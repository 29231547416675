export const FEED_STATE_BASE_FRAGMENT = `
    fragment feedStateBaseFragment on FeedState {
        uid
        lastViewedFeed
        user{
          uid
        }
        feedEntity {
          uid
        }
    }
  `;
